import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  ALL_PRODUCT_HOME_REQUEST,
  ALL_PRODUCT_HOME_SUCCESS,
  ALL_PRODUCT_HOME_FAIL,
  ADMIN_PRODUCT_REQUEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  VENDOR_PRODUCT_REQUEST,
  VENDOR_PRODUCT_SUCCESS,
  VENDOR_PRODUCT_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  NEW_PRODUCT_RESET,
  NEW_VENDOR_PRODUCT_REQUEST,
  NEW_VENDOR_PRODUCT_SUCCESS,
  NEW_VENDOR_PRODUCT_FAIL,
  NEW_VENDOR_PRODUCT_RESET,
  IMPORT_PRODUCT_REQUEST,
  IMPORT_PRODUCT_SUCCESS,
  IMPORT_PRODUCT_FAIL,
  IMPORT_PRODUCT_RESET,
  EXPORT_PRODUCT_REQUEST,
  EXPORT_PRODUCT_SUCCESS,
  EXPORT_PRODUCT_FAIL,
  EXPORT_PRODUCT_RESET,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_RESET,
  UPDATE_VENDOR_PRODUCT_REQUEST,
  UPDATE_VENDOR_PRODUCT_SUCCESS,
  UPDATE_VENDOR_PRODUCT_FAIL,
  UPDATE_VENDOR_PRODUCT_RESET,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_RESET,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  NEW_REVIEW_RESET,
  ALL_REVIEW_REQUEST,
  ALL_REVIEW_SUCCESS,
  ALL_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  DELETE_REVIEW_RESET,
  PRODUCT_NAME_REQUEST,
  PRODUCT_NAME_SUCCESS,
  PRODUCT_NAME_FAIL,
  CLEAR_ERRORS,
} from "../constants/productConstant";

export const productsReducer = (state = { products: [] },action) =>{

	switch (action.type) {
		case ALL_PRODUCT_REQUEST:
		case ADMIN_PRODUCT_REQUEST:
    case ALL_PRODUCT_HOME_REQUEST:
    case VENDOR_PRODUCT_REQUEST:
    case PRODUCT_NAME_REQUEST:
			return{
				loading: true,
        		products: [],
			}
		case ALL_PRODUCT_SUCCESS:
    case PRODUCT_NAME_SUCCESS:
			return{
				loading: false,
        		products: action.payload.products,
        		productsCount: action.payload.productsCount,
        		resultPerPage: action.payload.resultPerPage,
        		filteredProductsCount: action.payload.filteredProductsCount,
			}
		case ADMIN_PRODUCT_SUCCESS:
    case ALL_PRODUCT_HOME_SUCCESS:
    case VENDOR_PRODUCT_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
		case ALL_PRODUCT_FAIL:
		case ADMIN_PRODUCT_FAIL:
    case ALL_PRODUCT_HOME_FAIL:
    case VENDOR_PRODUCT_FAIL:
    case PRODUCT_NAME_FAIL:
			return{
				loading: false,
        		error: action.payload,
			}
		case CLEAR_ERRORS:
			return{
		        ...state,
		        error: null,
		    }
		default:
			return state;	
	}

};

export const newProductReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case NEW_PRODUCT_REQUEST:
    case NEW_VENDOR_PRODUCT_REQUEST:
    case IMPORT_PRODUCT_REQUEST:
    case EXPORT_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCT_SUCCESS:
    case NEW_VENDOR_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        product: action.payload.product,
      };
    case IMPORT_PRODUCT_SUCCESS:
    case EXPORT_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
      };
    case NEW_PRODUCT_FAIL:
    case NEW_VENDOR_PRODUCT_FAIL:
    case IMPORT_PRODUCT_FAIL:
    case EXPORT_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCT_RESET:
    case NEW_VENDOR_PRODUCT_RESET:
    case IMPORT_PRODUCT_RESET:
    case EXPORT_PRODUCT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const productReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
    case UPDATE_VENDOR_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCT_SUCCESS:
    case UPDATE_VENDOR_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
    case UPDATE_VENDOR_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCT_RESET:
    case UPDATE_VENDOR_PRODUCT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const productDetailsReducer = (state = { product: {} },action) =>{

	switch (action.type) {
		case PRODUCT_DETAILS_REQUEST:
			return{
				loading: true,
        ...state,
			}
		case PRODUCT_DETAILS_SUCCESS:
			return{
				loading: false,
        product: action.payload,
			}
		case PRODUCT_DETAILS_FAIL:
			return{
				loading: false,
        error: action.payload,
			}
		case CLEAR_ERRORS:
			return{
		        ...state,
		        error: null,
		    }
		default:
			return state;	
	}

};

export const newReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_REVIEW_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case NEW_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_REVIEW_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const productReviewsReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case ALL_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_REVIEW_SUCCESS:
      return {
        loading: false,
        reviews: action.payload,
      };
    case ALL_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const reviewReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_REVIEW_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};