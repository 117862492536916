import axios from "axios";
import download from "downloadjs";
import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  ALL_PRODUCT_HOME_REQUEST,
  ALL_PRODUCT_HOME_SUCCESS,
  ALL_PRODUCT_HOME_FAIL,
  ADMIN_PRODUCT_REQUEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  VENDOR_PRODUCT_REQUEST,
  VENDOR_PRODUCT_SUCCESS,
  VENDOR_PRODUCT_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  NEW_PRODUCT_RESET,
  NEW_VENDOR_PRODUCT_REQUEST,
  NEW_VENDOR_PRODUCT_SUCCESS,
  NEW_VENDOR_PRODUCT_FAIL,
  NEW_VENDOR_PRODUCT_RESET,
  IMPORT_PRODUCT_REQUEST,
  IMPORT_PRODUCT_SUCCESS,
  IMPORT_PRODUCT_FAIL,
  IMPORT_PRODUCT_RESET,
  EXPORT_PRODUCT_REQUEST,
  EXPORT_PRODUCT_SUCCESS,
  EXPORT_PRODUCT_FAIL,
  EXPORT_PRODUCT_RESET,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_RESET,
  UPDATE_VENDOR_PRODUCT_REQUEST,
  UPDATE_VENDOR_PRODUCT_SUCCESS,
  UPDATE_VENDOR_PRODUCT_FAIL,
  UPDATE_VENDOR_PRODUCT_RESET,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_RESET,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  ALL_REVIEW_REQUEST,
  ALL_REVIEW_SUCCESS,
  ALL_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  DELETE_REVIEW_RESET,
  PRODUCT_NAME_REQUEST,
  PRODUCT_NAME_SUCCESS,
  PRODUCT_NAME_FAIL,
  CLEAR_ERRORS,
} from "../constants/productConstant";

// Get All Products
export const getProduct = (keyword = "", currentPage = 1, price = [0, 25000], category, ratings = 0) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_PRODUCT_REQUEST });

      let link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${ratings}`;

      if (category) {
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&category=${category}&ratings[gte]=${ratings}`;
      }

      const { data } = await axios.get(link);

      dispatch({
        type: ALL_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCT_FAIL,
        payload: error.response.data.message,
      });
    }
};

// Get All Products For home
export const getAllProductsHome = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_PRODUCT_HOME_REQUEST });

    const { data } = await axios.get("/api/v1/products/home");

    dispatch({
      type: ALL_PRODUCT_HOME_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: ALL_PRODUCT_HOME_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getProductByCate = (category) => 
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_NAME_REQUEST });

      let cateLink = `/api/v1/products/${category}`;

      const { data } = await axios.get(cateLink);

      dispatch({
        type: PRODUCT_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_NAME_FAIL,
        payload: error.response.data.message,
      });
    }
};

// Get All Products For Admin
export const getAdminProducts = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_PRODUCT_REQUEST });

    const { data } = await axios.get("/api/v1/admin/products");

    dispatch({
      type: ADMIN_PRODUCT_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Products For Vendor
export const getVendorProducts = () => async (dispatch) => {
  try {
    dispatch({ type: VENDOR_PRODUCT_REQUEST });

    const { data } = await axios.get("/api/v1/vendor/products");

    dispatch({
      type: VENDOR_PRODUCT_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: VENDOR_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// export product list
export const exportFile = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_PRODUCT_REQUEST });

    const { data } = await axios.get(`/api/v1/exportFile`);
    download(data, new Date().toLocaleDateString() + '-data.csv');

    dispatch({
      type: EXPORT_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// import product list
export const importFile = (formData) => async (dispatch) => {
  try {
    dispatch({ type: IMPORT_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const { data } = await axios.post(
      `/api/v1/importFile`,
      formData,
      config
    );

    dispatch({
      type: IMPORT_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: IMPORT_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create Product
export const createProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/product`,
      productData,
      config
    );

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create Product by Vendor
export const createVendorProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_VENDOR_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/vendor/product`,
      productData,
      config
    );

    dispatch({
      type: NEW_VENDOR_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_VENDOR_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/admin/product/${id}`,
      productData,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product
export const updateVendorProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_VENDOR_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/vendor/product/${id}`,
      productData,
      config
    );

    dispatch({
      type: UPDATE_VENDOR_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VENDOR_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/product/${id}`);

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Product Details
export const getProductDetails = (id) => async (dispatch) => {
	try{
		dispatch({
			type: PRODUCT_DETAILS_REQUEST,
		});

		const {data} = await axios.get(`/api/v1/product/${id}`);

		dispatch({
			type: PRODUCT_DETAILS_SUCCESS,
			payload: data.product,
		});
	}catch(error){
		dispatch({
			type: PRODUCT_DETAILS_FAIL,
			payload: error.response.data.message,
		});
	}
};

// NEW REVIEW
export const newReview = (reviewData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REVIEW_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(`/api/v1/review`, reviewData, config);

    dispatch({
      type: NEW_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: NEW_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Reviews of a Product
export const getAllReviews = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALL_REVIEW_REQUEST });

    const { data } = await axios.get(`/api/v1/reviews?id=${id}`);

    dispatch({
      type: ALL_REVIEW_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: ALL_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Review of a Product
export const deleteReviews = (id, productId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_REVIEW_REQUEST });

    const { data } = await axios.delete(
      `/api/v1/reviews?id=${id}&productId=${productId}`
    );

    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};